import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const styles = {
  mainStyle: {
      color: 'var(--color-listgroup-item)',
      textAlign: 'left',
      backgroundColor: 'var(--color-navbar)',
      display: 'flex',
      justifyContent: 'center',
    }
};


function PrivacyPolicy() {

  const [wrapperWidth, setWrapperWidth] = useState('100%');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1280) {
        setWrapperWidth('60%');
      } else {
        setWrapperWidth('95%');
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Header
          accessToken={null}
          initiateDiscordLogin={null}
          handleLogout={null}
        />

    <main style={styles.mainStyle}>
      <div style={{width: wrapperWidth}}>

<h1>Privacy Policy</h1>
<p>
This privacy policy applies to TopLinks (hosted under top level domain toplinks.community

We at TopLinks take your privacy seriously. This policy covers the collection, processing and other use of personal data under the General Data Protection Regulation, named as GDPR) Reg. UE 2016/679), for which purpose the data controller is Baseline. By using Baseline´s website you consent to the policy.

This Privacy Policy governs the manner in which the website collects, uses, maintains and discloses information collected from users (each, a ‘User’) of the website (‘Site’). This privacy policy applies to the Site and all products and services offered by Baseline.
</p>

<h2>Information we collect</h2>
<p>
When you invite TopLinks to your server, we cache the guild object, channel objects and role objects. Additionally, we may also cache any <a href="https://discord.com/developers/docs/resources/user#user-object-user-structure">user</a> or <a href="https://discord.com/developers/docs/resources/guild#guild-member-object-guild-member-structure">member</a> objects that Discord provides TopLinks with in the GUILD_CREATE payload. Upon interaction with the bot, we may also cache your member and user objects if we do not already do so. We store this data solely for operation of the TopLinks service to prevent excessive API requests to the Discord API.  
  
When logging into our website, we also collect all guild objects for the servers you are a member of. This is so we can verify your ownership / status in the server, and provide a list of servers to manage. In addition if our bot has joined a server and you post a link, we will save this message to our database to provide the given link.
</p>
<h2>Personal identification information</h2>
<p>
We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, email address and their full name.  
Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
</p>
<h2>Non-personal identification information</h2>
<p>
We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
</p>

<h2>Use, storage and disclosure of your information</h2>
<p>
We may hold and process this personal data in accordance with the GDPR, we will not transfer, share, sell, rent or lease your personal data to third parties and other subjects as accountants, legal consultants and service providers.

We may share your personal data within our group of companies, but it will still be subject to these terms if we do.

TopLinks may collect and use Users personal information for the following purposes:

- To improve customer service Information you provide helps us respond to your customer service requests and support needs more efficiently.
- To personalize user experience We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
- To meet our contractual commitments to you
- To notify the User about any changes to our website, such as improvements or service/product changes, that may affect our service;  
    If the User is an existing customer, we may contact the User with information about goods and services similar to those which were the subject of a previous sale to User;  
    Further, we may use your data so that the User can be provided with information about unrelated goods and services which we consider may be of interest to User. We may contact the User about these goods and services by any of the methods that User consented to at the time your information was collected;
- To assist fraud protection.

Please be advised that we do not reveal information about identifiable individuals to our advertisers but we may, on occasion, provide them with aggregate statistical information about our visitors.

We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.

As part of the services offered to User, for example through our website, the information you provide to us may be transferred to and stored in countries outside of the European Economic Area (EEA) as we use remote website server hosts to provide the website and some aspects of our service, which may be based outside of the EEA, or use servers based outside of the EEA – this is generally the nature of data stored in “the Cloud”.  
It may also be processed by staff operating outside the EEA who work one of our suppliers, e.g. our website server host, or work for us when temporarily outside of the EEA. A transfer of your personal data may happen if any of our servers are located in a country outside of the EEA or one of our service providers is located in a country outside of the EEA.  
If we transfer or store User’s personal data outside the EEA in this way, we will take steps with the aim of ensuring that your privacy rights continue to be protected, as outlined in this privacy policy and in accordance with the GDPR. If User uses our service while User is outside the EEA, User’s personal data may be transferred outside the EEA in order to provide User with these services.

We do not use or disclose sensitive personal data, such as race, religion, or political affiliations, without your explicit consent.

Otherwise, we will process, disclose or share User’s personal data only if required to do so by law or in the good faith belief that such action is necessary to comply with legal requirements or legal process served on us or the website.
</p>

<h2>Security</h2>
<p>
The transmission of information via the Internet or email is not completely secure. Although we will do our best to protect User’s personal data, we cannot guarantee the security of data while Users are transmitting it to our site; any such transmission is at your own risk. Once we have received User’s personal data, we will use strict procedures and security features to try to prevent unauthorised access.

Where we have given User (or where User has chosen) a password so that User can access certain parts of our site, User is responsible for keeping this password confidential. User should choose a password that is not easy for someone to guess.

All traffic is routed through Cloudflare, whose service we use for web performance and security. You can view Cloudflare's privacy policy [here](https://www.cloudflare.com/privacypolicy/).
</p>

<h2> How can I contact you regarding concerns with the TopLinks service?</h2>
<p>
We are happy to resolve any data processing of security concerns you may have. The best way to get in contact with us is writing an email to contact@toplinks.community
</p>
<h2>How can I request my data or data removal?</h2>
<p>
Please write an email to removal[at]toplinks.community. Unfortunately, we are unable to help you with these queries via external channels, as we would not be able to reliably verify your identity. We will handle your request within 30 days of it being logged.
</p>   
      </div>
    </main>


    <Footer />
    </div>
  );
}

export default PrivacyPolicy;