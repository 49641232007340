import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components here
import Home from './pages/Home/Home';
import Disclaimer from './pages/disclaimer';
import LegalNotice from './pages/legalnotice';
import PrivacyPolicy from './pages/privacypolicy';
import Terms from './pages/terms';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oauth-callback" element={<Home />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/legalnotice" element={<LegalNotice />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;