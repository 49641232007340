
const styles = {
appStyle: {
    backgroundColor: 'var(--color-page-bg)', // #F4F5F8
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

bodyStyle: {
    margin: 0,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

headerStyle: {
    color: 'var(--color-white)',
    height: '70px',
    textAlign: 'center',
    zIndex: 100,
    boxShadow: '0 3px 3px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'var(--color-navbar)',
  },

headerButton: {
    marginRight: '20px',
  //  backgroundColor: '#665aef',
  },

logoStyle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Noto Sans, sans-serif',
    fontSize: '32px',
    fontWeight: 800,
    marginLeft: '20px',
    color: 'var(--color-logo-top)', // #3F3F3F rgb(228, 226, 226)',
  },

firstSpanStyle: {
    color: 'var(--color-logo-links)',
  },

pageStyle: {
    backgroundColor: 'var(--color-page-bg)', // Set background color to #F4F5F8 , 67 70 73 , 42 44 45
  },

modalStyle: {
    color: 'var(--color-white)', // White font color
    backdropFilter: 'blur(5px)'
  },

modalHeaderStyle: {
    color: 'var(--color-modal-header)', // White font color
  },

modalTitleStyle: {
    marginRight: '40px',
  },

  
listGroupStyle: {
  p: 0,
  width: '100%',
  maxWidth: 600,
  borderColor: 'var(--color-listgroup-border)',
  '--bs-list-group-border-color': 'var(--color-listgroup-border)', 
  backgroundColor: 'var(--color-listgroup-bg)',
  backdropFilter: 'blur(1em)',
},


listGroupHoverStyle: {
    backgroundColor: 'var(--color-listgroup-hover-bg)',
    color: 'var(--color-listgroup-hover)',
  },

listGroupItemStyle: {
    color: 'var(--color-listgroup-item)',
    backgroundColor: 'var(--color-listgroup-bg)',
  },

listGroupItemClickedStyle: {
  backgroundColor: 'var(--color-listgroup-item-clicked)',
},

linkClickedStyle: {
  color: 'var(--color-link-clicked)',
  },

infoButtonStyle: {
    backgroundColor: 'var(--color-listgroup-bg)',
    color: 'var(--color-button-info)',
    border: '0px',
  },

infoButtonHoverStyle: {
    color: 'var(--color-button-info-hover)',
  },

infoButtonClickedStyle: {
    color: 'var(--color-button-info-clicked)',
    backgroundColor: 'var(--color-listgroup-item-clicked)',
  },

}
  // Export all styles
export default styles;