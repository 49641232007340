import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const styles = {
  mainStyle: {
      color: 'var(--color-listgroup-item)',
      textAlign: 'left',
      backgroundColor: 'var(--color-navbar)',
      display: 'flex',
      justifyContent: 'center',
    }
};


function Disclaimer() {

  const [wrapperWidth, setWrapperWidth] = useState('100%');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1280) {
        setWrapperWidth('60%');
      } else {
        setWrapperWidth('100%');
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>

    <Header
        isSignedIn={null}
        initiateDiscordLogin={null}
        handleLogout={null}
      />
    <main style={styles.mainStyle}>
      <div style={{width: wrapperWidth}}>
        <h1>Disclaimer</h1>
        <h2>General</h2>
        <p>All content provided herein our website, hyperlinked sites, associated applications, forums, blogs, social media accounts and other platforms (“Site”) is for your general information only, procured primarily from third party sources. We make no warranties of any kind in relation to our content and services, including but not limited to accuracy, security and updatedness. No part of the content and services that we provide constitutes financial advice, legal advice or any other form of advice meant for your specific reliance for any purpose, nor any dealing in (or promotion of) securities for which a licence is required. Any use or reliance on our content and services is solely at your own risk and discretion. You should conduct your own research, review, analyse and verify our content and services before relying on or using them. No content on our Site is meant to be a solicitation or offer.</p>

        <h2>No Investment Advice</h2>
        <p>The information provided on toplinks.community does not constitute investment advice, financial advice, trading advice, or any other sort of advice, and you should not treat any of the website’s content as such. TopLinks does not recommend that any cryptocurrency should be bought, sold, or held by you. Nothing on this website should be taken as an offer to buy, sell or hold a cryptocurrency. Do conduct your own due diligence and consult your financial advisory before making any investment decision. TopLinks will not be held responsible for the investment decisions you make based on the information provided on the website.</p>

        <h2>Accuracy of Information</h2>
        <p>TopLinks will strive to ensure accuracy of information listed on this website although it will not hold any responsibility for any missing or wrong information. You understand that you are using any and all information available here AT YOUR OWN RISK. You should take adequate steps to verify the accuracy and completeness of any information on the website and do your own research.</p>
      </div>
    </main>


    <Footer />
    </div>
  );
}

export default Disclaimer;