import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const styles = {

    modalStyle: {
        color: 'var(--color-white)', // White font color
        backdropFilter: 'blur(5px)'
      },
    
    modalHeaderStyle: {
        color: 'var(--color-modal-header)', // White font color
      },
    
    modalTitleStyle: {
        marginRight: '40px',
      },

    modalBodyStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
    
};

const JoinServerModal = ({ isOpen, onClose, initiateDiscordLogin }) => {

  const joinDiscordGuild = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null; // Set opener to null for additional security
    }
  };

  return (
    <Modal className="modal" show={isOpen} onHide={onClose} style={styles.modalStyle}>
    <Modal.Header style={styles.modalHeaderStyle}>
      <Modal.Title style={styles.modalTitleStyle}>Join the University</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <span style={{fontSize:'1.0em'}}>To gain access to the links, you must become a member of the Discord server.</span>
      <div style={styles.modalBodyStyle}>
        <Button variant="primary" className="justify-content-between align-items-center" onClick={() => joinDiscordGuild('https://discord.gg/joinvvv')}>
          Join Server
        </Button>
      </div>
    </Modal.Body>
    <Modal.Footer className="d-flex">

      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default JoinServerModal;