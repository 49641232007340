import React from 'react';
import './ToggleSwitch.css'; // Import CSS for styling toggle switch

const ToggleSwitch = ({ isOn, handleToggle }) => {
  return (
    <div className="toggle-switch" onClick={handleToggle}>
      <input type="checkbox" className="toggle-switch-checkbox" checked={isOn} readOnly />
      <label className="toggle-switch-label" htmlFor="toggleSwitch">
        <span className="toggle-switch-inner" />
      </label>
    </div>
  );
};

export default ToggleSwitch;