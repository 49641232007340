import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const styles = {
  mainStyle: {
      color: 'var(--color-listgroup-item)',
      height: '70px',
      textAlign: 'left',
      backgroundColor: 'var(--color-navbar)',
      display: 'flex',
      justifyContent: 'center',
    },
    wrapperStyle: {
      marginTop: '50px',
    }
};


function LegalNotice() {
  return (
    <div>
      <Header
          accessToken={null}
          initiateDiscordLogin={null}
          handleLogout={null}
        />

    <main style={styles.mainStyle}>
      <div style={styles.wrapperStyle}>
        <span>
      Ben Havizka<br></br>
      c/o Block Services<br></br>
      Stuttgarter Str. 106<br></br>
      70736 Fellbach<br></br>
      </span>
      <br></br>
      <br></br>
      <span>
      <b>Contact</b><br></br>
      E-Mail: hello[at]toplinks.community<br></br>
      Telephone: +4915679 152114 (weekdays from 10am to 5pm CET)<br></br>
      </span>
      </div>
    </main>


    <Footer />
    </div>
  );
}

export default LegalNotice;