import React, { useEffect, useState }from 'react';
import { Navbar, Button } from 'react-bootstrap';
import './Header.css';
import ToggleSwitch from './ToggleSwitch';

const Header = ({ isSignedIn, initiateDiscordLogin, handleLogout }) => {


  const [mode, setMode] = useState(localStorage.getItem('mode') ||
  (window.matchMedia('(prefers-color-scheme: dark)').matches
   ? 'dark'
   : 'light')
 );

    // Function to toggle between light and dark mode
  const toggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('mode', newMode);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('mode');
    if (storedMode) {
      setMode(storedMode);
    } else {
      setMode(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
      );
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode === "dark" ? "dark" : "light");
  }, [mode]);


  if (initiateDiscordLogin) {
    return (
      <Navbar className="header-style">
        <ToggleSwitch isOn={mode === 'dark'} handleToggle={toggleMode} />

        <div className="empty"> </div>
        {/* Logo or Brand */}
        <Navbar.Brand>
          <a href="/" className="logo-style">University<span className="first-span">Links</span></a>
        </Navbar.Brand>

     

      <div className="header-controls">
        {isSignedIn ? (
          <Button
            variant="danger"
            className="header-button"
            onClick={handleLogout}
            >
            Logout
          </Button>
        ) : (
          <Button
            variant="primary"
            className="header-button"
            onClick={initiateDiscordLogin}
            >
            Login with Discord
          </Button>
        )}
      </div>
    </Navbar>
    );
  }

  // If initiateDiscordLogin is null, render only the Navbar without the buttons
  return (
    <Navbar className="header-style">
      {/* Logo or Brand */}
      <Navbar.Brand>
        <a href="/" className="logo-style">University<span className="first-span">Links</span></a>
      </Navbar.Brand>
    </Navbar>
  );

};

export default Header;
