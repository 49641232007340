import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaTwitter } from 'react-icons/fa';

const styles = {

    modalStyle: {
        color: 'var(--color-white)', // White font color
        backdropFilter: 'blur(5px)'
      },
    
    modalHeaderStyle: {
        color: 'var(--color-modal-header)', // White font color
      },
    
    modalTitleStyle: {
        marginRight: '40px',
      },
    
};


const InfoModal = ({ isOpen, onClose, selectedLink }) => {
  // Inside your component function
  const [isHovered, setIsHovered] = useState(false);
    
    const CustomCloseButton = ({ onClick }) => {
        return (
          <Button variant="outline-light" onClick={onClick} className="custom-close-button">
            {/* Your custom close button icon or text */}
            &#10005;
          </Button>
        );
      };

  return (
    <Modal className="modal" show={isOpen} onHide={onClose} style={styles.modalStyle}>
    <Modal.Header style={styles.modalHeaderStyle}>
      <Modal.Title style={styles.modalTitleStyle}>{selectedLink?.name}</Modal.Title>
      <CustomCloseButton onClick={onClose}/>
    </Modal.Header>
    { selectedLink?.description && (<Modal.Body>
      <p>{selectedLink?.description}</p>
    </Modal.Body>)}
    <Modal.Footer className="d-flex justify-content-between align-items-center">
      {selectedLink?.contributors ? (
        <div className="d-flex align-items-center">
          <span>{selectedLink?.contributors?.name}</span>
          {selectedLink?.contributors?.twitter_url && (
            <>
            &nbsp;
            <a href={selectedLink?.contributors?.twitter_url} target="_blank" rel="noopener noreferrer" className="text-muted ml-2">
              <FaTwitter style={{
                  color: isHovered ? '#1DA1F2' : 'var(--color-logo-twitter)',
                  transition: 'color 0.3s', // Add transition effect for color change
                  ':hover': { color: '#1DA1F2' } // Change color on hover
                }}
                onMouseEnter={() => setIsHovered(true)} // Set isHovered to true on mouse enter
                onMouseLeave={() => setIsHovered(false)} // Set isHovered to false on mouse leave
                />
            </a>
          </>
          )}
        </div>
      ) : (
        <span>{selectedLink?.contributors?.name}</span>
      )}
    </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;