import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const styles = {

    modalStyle: {
        color: 'var(--color-white)', // White font color
        backdropFilter: 'blur(5px)'
      },
    
    modalHeaderStyle: {
        color: 'var(--color-modal-header)', // White font color
      },
    
    modalTitleStyle: {
        marginRight: '40px',
      },
    
};

const DiscordModal = ({ isOpen, onClose, initiateDiscordLogin }) => {

  return (
    <Modal className="modal" show={isOpen} onHide={onClose} style={styles.modalStyle}>
    <Modal.Header style={styles.modalHeaderStyle}>
      <Modal.Title style={styles.modalTitleStyle}>Login with Discord</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>To access this link, you need to join the Discord server and authorize with Discord.</p>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between align-items-center">
      <Button variant="primary" onClick={initiateDiscordLogin}>
        Login with Discord
      </Button>
      <Button variant="secondary" onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default DiscordModal;