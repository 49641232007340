import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const styles = {
  mainStyle: {
      color: 'var(--color-listgroup-item)',
      textAlign: 'left',
      backgroundColor: 'var(--color-navbar)',
      display: 'flex',
      justifyContent: 'center',
    }
};


function Terms() {

  const [wrapperWidth, setWrapperWidth] = useState('100%');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1280) {
        setWrapperWidth('60%');
      } else {
        setWrapperWidth('95%');
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Header
          accessToken={null}
          initiateDiscordLogin={null}
          handleLogout={null}
        />

    <main style={styles.mainStyle}>
      <div style={{width: wrapperWidth}}>
<h1>Terms of Service</h1>
<p>
Please read these terms of service ("terms", "terms of service") carefully before using the TopLinks Discord bot and toplinks.community (the "service") operated by toplinks.community ("us", 'we", "our").  We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. By using the service, you represent and warrant that you agree to these terms, and have the legal capacity to do so.

We take your privacy seriously. This policy covers the collection, processing and other use of personal data under the General Data Protection Regulation, named as GDPR) Reg. UE 2016/679), for which purpose the data controller is TopLinks. By using this website you consent to the policy. This Privacy Policy governs the manner in which the website collects, uses, maintains and discloses information collected from users (each, a ‘User’) of the website (‘Site’).
</p>

<h2>Access to website</h2>
<p>
By accessing this web site, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law. You are responsible for ensuring that all persons who access the Website through your Internet connection are aware of this Agreement, and that they comply with it. Our Website is intended for people in Europe. Content available on our site may not be suitable or accessible from other countries. We may restrict the availability of our Website or our services or goods to any person or country at any time without prior notice. If you access our site from outside Europe, you do so at your own risk.
</p>
<h2>Use of website and services</h2>
<p>
Any material that is available free of charge on this Website you are permitted to use for your own personal use, but not for distribution (including sharing or making available for use by others by any means, whether free of charge or for payment) or resale. You promise that you will not resell or otherwise attempt to commercially benefit from the Website content.  
All intellectual property (including without limitation copyright) in this Website and its content belongs to us and are protected by copyright laws around the world.  
You have permission to print one copy and download extracts of any web page(s) from our Website for

- your personal use and
- to mention to colleagues in your organisation.

You do not have permission to use any illustrations, photographs, video or audio files separately from any accompanying text. Our status (and that of any identified contributors) as the authors of material must always be acknowledged and you must not use the material on the Website in a derogatory manner.  
Otherwise than in accordance with the text above, no part of this Website may be used without our prior permission. If, in breach of these terms of use, you print off, copy or download any part of our Website, then your right to use our Website and is contents is automatically revoked – you must then immediately destroy any copies you have made of any part of our Website.  
We do not guarantee that our Website will be free from bugs or viruses or protected at all times against hacking. You are responsible for setting up your computer to access our Website and using appropriate firewall and virus protection software.  
Permission is granted to temporarily download/use one copy of the materials (information) for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:

- Modify or copy the materials;
- Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
- Attempt to decompile or reverse engineer any software contained on the TopLinks website;
- Remove any copyright or other proprietary notations from the materials;
- Transfer the materials to another person or ‘mirror’ the materials on any other server.

This license shall automatically terminate if you violate any of these restrictions and may be terminated at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
</p>
<h2>SITE UPTIME</h2>

<p>
We take all reasonable steps to ensure that this Website is available 24 hours every day, 365 days per year. However, websites do sometimes encounter downtime due to server and, other technical issues. Therefore we will not be liable if this Website is unavailable at any time. We do not guarantee that our Website, or any content on it, will be available uninterrupted.  
Access to our Website is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our site without notice. We will not be liable to you if for any reason our Website is unavailable at any time or for any period.  
This Website may be temporarily unavailable due to issues such as system failure, maintenance or repair or for reasons beyond our control. Where possible we will try to give our visitors advance warning of maintenance issues, but shall not be obliged to do so.
</p>
<h2>VISITOR CONDUCT</h2>
<p>
With the exception of personally identifiable information, the use of which is covered under our privacy policy, any material you send or post to this Website shall be considered non-proprietary and not confidential. Unless you advise us to the contrary, we will be free to copy, disclose, distribute, incorporate and otherwise use such material for any and all purposes.  
  
When using this Website you must not attempt unauthorised access to our Website, the servers on which our Website or database are stored or any servers, computer or database connected to our site, and you shall not post or send to or from this Website any material:

- for which you have not obtained all necessary consents;
- that is discriminatory, obscene, pornographic, defamatory, liable to incite racial hatred, in breach of confidentiality or privacy, which may cause annoyance or inconvenience to others, which encourages or constitutes conduct that would be deemed a criminal offence, give rise to a civil liability, or otherwise is contrary to the law in Switzerland; or
- which is harmful in nature, including, and without limitation, denial-of-service attacks, computer viruses, trojans, worms, logic bombs, corrupted data, or other potentially harmful software or data.

By breaching this provision, you would commit a criminal offence. We will report any such breach to the relevant law enforcement authorities and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.

We will fully cooperate with any law enforcement authorities or court order requiring us to disclose the identity or other details of any person posting material to this Website in breach of Paragraphs above.  
If a third party alleges that content posted by you on our site is in breach of their intellectual property rights or their right to privacy, we may disclose your identity to such third party.  
We reserve the right to remove any posting you make on our Website for any reason and without notice, but particularly if your post does not comply with these terms of use Links to and from other websites.

Any links to third party websites located on this Website are provided for your convenience only. We have not reviewed each third party website and have no responsibility for such third party websites or their content. We do not endorse the third party websites or make representations about them or any material contained in them. If you choose to access a third party website linked to from this Website, it is at your own risk. We will not be liable for any loss or damage that may arise from your use of them.

If you would like to link to this Website, you may only do so on the basis that you link to, but do not replicate, any page on this Website, and subject to the following conditions:

- you do not in any way imply that we are endorsing any services or products unless this has been specifically agreed to by us in writing;
- you do not misrepresent your relationship with us or present any false information about us;
- you do not link from a website that is not owned by you;
- your website does not contain content that is offensive, controversial, infringes any intellectual property rights or other rights of any other person or does not comply in any way with the law in Switzerland

If you choose to link to our Website in breach of Paragraph you shall fully indemnify us for any loss or damage suffered as a result of your actions.
</p>

<h2>DISCLAIMER AND EXCLUSION OF LIABILITY - PLEASE READ CAREFULLY</h2>
<p>
In no event shall TopLinks be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on TopLinks internet site, even if TopLinks or a TopLinks authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.

We take reasonable steps to ensure that the information on this Website is correct at the time of first publishing. However, we do not guarantee the correctness or completeness of material on this Website or the absence of inaccuracies, omissions or typographical errors.  
We may make changes to the material on this Website at any time and without notice, including corrections. The material on this Website may be out of date, or on rare occasions incorrect and we make no commitment to ensure that such material is correct or up-to-date.  
We are not liable to send you notice of any changes or corrections made.

The material at this Website is provided without any conditions or warranties of any kind. To the maximum extent permitted by law, we provide access and use of this Website on the basis that we exclude all representations, warranties and conditions which but for these Terms may have effect in relation to this Website.

All information and services are provided “as is” without warranty or condition of any kind. We disclaim all warranties and conditions with regard to this information and services, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.

Any information on the Website in many cases will be an abbreviated and simplified summary for general use or general guidance only, and is not a substitute for taking specific advice on your own situation from an appropriate expert. Before taking, or refraining from, any action on the basis of the content of our Website, you must obtain suitable advice from a professional or specialist.

You use the Website at your own risk – neither we, nor our agents, employees and sub-contractors, shall be liable to you or any other party for any losses or damages whatsoever or howsoever arising in connection with the website (whether under these terms and conditions or other contract or as a result of any misrepresentation, misstatement or tortious act or omission, including negligence).

Neither we nor any other party (whether or not involved in producing, maintaining or delivering this Website) shall be liable or responsible for any kind of loss or damage that may result to you or a third party as a result of your or their use of our Website. This exclusion shall include (without limitation) servicing or repair costs and any other direct, indirect or consequential loss, and whether in tort, contract or otherwise in connection with this Website.

We shall have no liability to any users for loss (whether in contract, tort, including without limitation negligence, breach of statutory duty or otherwise, and whether foreseeable or not) relating to:

- Use of, or inability to use, our Website;
- Use of, or reliance on, the contents of our Website.
- In addition if you are a business user, we will not be liable to you for:
    - - lost profit or turnover;
    - - interruption or disruption of your business;
    - - your failure to make anticipated savings;
    - - lost business opportunities or damage to your goodwill or reputation;
    - - indirect or consequential losses.

In addition if you are a consumer, you agree not to use our site for any commercial or business purposes, and we shall have no liability to you for anything mentioned in Paragraphs above.

We shall have no liability to you for any losses caused by a distributed denial-of-service attacks, computer viruses, trojans, worms, logic bombs, corrupted data, or other potentially harmful software or data that may damage your computer system, software and data from your use of our Website or your downloading of any content on it, or on any website linked to it.

Nothing in these Terms shall exclude or limit liability for (i) death or personal injury caused by negligence; (ii) fraud; (iii) misrepresentation as to a fundamental matter; or (iv) any liability which cannot be excluded or limited under Swiss law.
</p>
<h2>MISCELLANEOUS</h2>
<p>
If any part of this Agreement is determined to be invalid or unenforceable under any applicable law, including, but not limited to, the above disclaimers and liability limitations, then the invalid or unenforceable provision will be deemed to be replaced by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Agreement will continue in effect.  
Unless otherwise specified, this Agreement constitutes the entire Agreement between you and us with respect to the use of the Website and it supersedes all prior communications and proposals, whether electronic, oral or written, between you and us with respect to the use of the Website.

You must not assign or otherwise transfer the Agreement, or any right granted under it, without our written consent. We can freely transfer our rights under the Agreement.

Any failure by us to enforce or exercise any provision of the Agreement, or any related right, will not be a waiver of that provision or right.

We may provide you with notices, including those regarding changes to the Agreement, by email, regular mail, postings on the Website, or other reasonable means now known or developed in the future.
</p>

<h2>REVISION</h2>
<p>
The materials appearing on TopLinks website could include technical, typographical, or photographic errors. TopLinks does not warrant that any of the materials on its website are accurate, complete, or current. TopLinks may make changes to the materials contained on its website at any time without notice. TopLinks does not, however, make any commitment to update the materials.
</p>
<h2>LINKS</h2>
<p>
TopLinks has not reviewed all of the sites linked to its internet website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by TopLinks of the site. Use of any such linked website is at the user’s own risk.
</p>
<h2>TERMS OF USE MODIFICATION</h2>
<p>
TopLinks may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
</p>
<h2>GOVERNING JURISDICTION</h2>
<p>
These Terms shall be governed by and construed in accordance with German law. Any dispute(s) arising in connection with these Terms are subject to the exclusive jurisdiction of the courts of Berlin, Germany
</p>
      </div>
    </main>


    <Footer />
    </div>
  );
}

export default Terms;