import { API_BASE_URL, AUTH_BASE_URL, AUTH_REFRESH_ENDPOINT, LINK_LISTS_ENDPOINT } from '../constants/api';

export const fetchLinkLists = async (accessToken) => {
    try {

      const response = await fetch(`${API_BASE_URL}${LINK_LISTS_ENDPOINT}`, {
        headers: {
         Authorization: `Bearer ${accessToken}`,
      }, });

      if (!response.ok) {
        if (response && response.status === 401) {
          return 401;
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      return data.topics;

    } catch (error) {
      console.error('Error fetching link list:', error);
    }
  };

 export const refreshAccessToken = async (refreshToken) => {
    try {
        // Send a request to the server with the refresh token
        const response = await fetch(`${AUTH_BASE_URL}${AUTH_REFRESH_ENDPOINT}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            const newAccessToken = data.access_token;
            // Update the access token state and save it to local storage
            return newAccessToken;
        } else {
           return false;
        }
    } catch (error) {
        throw new Error(`Error refreshing access token: ${error.message}`);
    }
  };