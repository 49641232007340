import React from 'react';
import './Footer.css'; // Assuming the custom CSS file is named Footer.css and is located in the same directory as the component

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer-content">
        <p className="copyright">Copyright &copy; 2024 TopLinks.
        <br>
        </br>
            <a href="https://twitter.com/Z0rk153" target="_blank" rel="noopener noreferrer" style={{ fontSize: '10px' }}>by @z0rk</a>
        </p>

        <div className="footer-links-container" >
            <a href="/disclaimer" className="footer-link">Disclaimer</a>
            <span style={{ margin: '0 10px' }}></span>
            <a href="/legalnotice" className="footer-link">Legal Notice</a>
            <span style={{ margin: '0 10px' }}></span>
            <a href="/terms" className="footer-link">Terms and Services</a>
            <span style={{ margin: '0 10px' }}></span>
            <a href="/privacypolicy" className="footer-link">Privacy Policy</a>
            </div>
        </div>
  </footer>
  );
};

export default Footer;
