import React, { useState, useEffect } from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Container,
} from 'react-bootstrap';
import '../../css/index.css';
import '../../css/colors.css';
import { BsInfoCircle } from 'react-icons/bs';
import styles from './styles';
import InfoModal from '../../components/Modals/InfoModal';
import DiscordModal from '../../components/Modals/DiscordModal';
import JoinServerModal from '../../components/Modals/JoinServerModal';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { fetchLinkLists, refreshAccessToken } from '../../services/api'; // Import API functions
import { AUTH_BASE_URL, AUTH_LOGIN_ENDPOINT } from '../../constants/api';

function Main() {
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discordModalOpen, setDiscordModalOpen] = useState(false);
  const [joinServerModalOpen, setJoinServerModalOpen] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [hoveredInfoItemId, setHoveredInfoItemId] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token'));
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [clickedInfoButtons, setClickedInfoButtons] = useState(() => {
    // Retrieve button state from local storage when the component mounts
    const storedState = localStorage.getItem('clickedInfoButtons');
    return storedState ? JSON.parse(storedState) : [];
  });
  const [clickedLinks, setClickedLinks] = useState(() => {
    // Retrieve button state from local storage when the component mounts
    const storedState = localStorage.getItem('clickedLinks');
    return storedState ? JSON.parse(storedState) : [];
  });

    // Fetch link list from the backend server with access token
    const handleLogout = () => {
      // Remove access_token from local storage
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setAccessToken(null);
      setRefreshToken(null);
      setIsSignedIn(false);
    };
  
  useEffect(() => {
      // Check if tokens are present in local storage
      setAccessToken(localStorage.getItem('access_token'));
      setRefreshToken(localStorage.getItem('refresh_token'));
    }, []);
  
      // Simulate obtaining the access token from the URL after Discord login
  useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');
      const refreshToken = urlParams.get('refresh_token');
  
      if (accessToken && refreshToken) {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
        window.location.href = '/';
      }

      if (urlParams.has('error') && urlParams.get('error') === 'no-guild-server') {
        // Remove the query parameter from the URL
        const urlWithoutQueryString = window.location.origin;
        window.history.replaceState({}, document.title, urlWithoutQueryString);
        setJoinServerModalOpen(true);
      }

    }, []);

  useEffect(() => {
    // Save button state to local storage whenever it changes
    localStorage.setItem('clickedInfoButtons', JSON.stringify(clickedInfoButtons));
  }, [clickedInfoButtons]);

  useEffect(() => {
    // Save Links local storage whenever it changes
    localStorage.setItem('clickedLinks', JSON.stringify(clickedLinks));
  }, [clickedLinks]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const linkLists = await fetchLinkLists(accessToken)

        if (!linkLists) {
          setIsSignedIn(false);
          return;
        }

        if (linkLists === 401) {
          // Refresh the access token
          let newToken =  await refreshAccessToken(refreshToken);

          if (!newToken) {
            handleLogout();
          }

          setAccessToken(newToken);
          return;
        }

        let url = linkLists[0]?.links[0]?.url; 

        if (!url) {
          setIsSignedIn(false);
        } else {
          setIsSignedIn(true);
        }

        const leftList = [];
        const rightList = [];

        linkLists.forEach((item, index) => {
          if (!item?.links[0]) {
            return;
          }

          let order = item?.order ?? index;

          if (order % 2 === 0) {
            rightList.push(item);

          } else {
            leftList.push(item);
          }
        });


        setLeftList(leftList);
        setRightList(rightList);


        // setLeftList(linkLists.filter((_, index) => index % 2 === 0));
        // setRightList(linkLists.filter((_, index) => index % 2 !== 0));

      } catch (error) {
        console.error('Error fetching link list:', error);
      }
    };

    fetchData();

  }, [accessToken, refreshToken]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDiscordModalOpen(false);
    setJoinServerModalOpen(false);
  };

  const openSecurely = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null; // Set opener to null for additional security
    }
  };

  const handleOpenLink = (link) => {
    if (!link.url) {
      // Open Discord modal for empty URL
      setDiscordModalOpen(true);
    } else {
      openSecurely(link.url);

      setClickedLinks((prevLinks) => {
        if (!prevLinks.includes(link.short_url)) {
          return [...prevLinks, link.short_url];
        }
        return prevLinks;
      });
    }
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleInfoItemHover = (infoItemId) => {
    setHoveredInfoItemId(infoItemId);
  };
  

  const handleInfoButtonClick = (link) => {
    setSelectedLink(link);
    setClickedInfoButtons((prevButtons) => {
      if (!prevButtons.includes(link.short_url)) {
        // Add the clicked button's ID to the list
        return [...prevButtons, link.short_url];
      }
      // Button is already clicked, no change needed
      return prevButtons;
    });
    setIsModalOpen(true);
  };

  const getFaviconUrl = (url) => {

    if (!url) {
      return;
    }
    
    // Extracting the domain to form the favicon URL
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}`;
  };

  const getKeyIndex = (inputParam) => {
    const { short_url } = inputParam;
    return short_url;
  };

  const initiateDiscordLogin = () => {
    window.location.href = `${AUTH_BASE_URL}${AUTH_LOGIN_ENDPOINT}`; // Change to your server URL
  };

  const renderLists = (lists) => {
    return lists.map((linkList, index) => (
      <div key={index} className="list-container">
        <h2 style={{ color: 'var(--color-list-headline)' }}>{linkList.name}</h2>
        <ListGroup style={styles.listGroupStyle}>
          {linkList.links.map((link) => (

            <ListGroupItem
              key={getKeyIndex(link)}
              className={`d-flex align-items-center`}
              onMouseOver={() => handleItemHover(getKeyIndex(link))}
              onMouseOut={() => handleItemHover(null)}
              style={{
                ...styles.listGroupItemStyle,
                ...(hoveredItemId === getKeyIndex(link) ? styles.listGroupHoverStyle : {}),
                ...(clickedLinks.includes(getKeyIndex(link))
                ? styles.listGroupItemClickedStyle
                : {}),
              }}
            >
               { link.url &&
              <img
                src={getFaviconUrl(link.url)}
                alt={`Favicon for ${link.name}`}
                className="mr-1"
              />
               }
              <span
                className="flex-grow-1 text-decoration-none"
                style={{
                   cursor: 'pointer',
                   marginLeft: '10px',
                   ...(clickedLinks.includes(getKeyIndex(link))
                   ? styles.linkClickedStyle
                   : {}),
                  }}
                onClick={() => handleOpenLink(link)}
              >
                {link.name}
              </span>
              <Button
                  variant={clickedInfoButtons.includes(getKeyIndex(link)) ? 'success' : 'info'}
                  className="mx-2"
                  onMouseOver={() => handleInfoItemHover(getKeyIndex(link))}
                  onMouseOut={() => handleInfoItemHover(null)}
                  style={{
                    ...styles.infoButtonStyle,
                    ...(hoveredInfoItemId === getKeyIndex(link) ? styles.infoButtonHoverStyle : {}),
                    ...(clickedInfoButtons.includes(getKeyIndex(link))
                      ? styles.infoButtonClickedStyle
                      : {}),
                  }}
                  onClick={() => handleInfoButtonClick(link)}
                >
                  <BsInfoCircle size={18} />
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
  ))
  };


  return (
    <div style={styles.appStyle}>

      <Header
        isSignedIn={isSignedIn}
        initiateDiscordLogin={initiateDiscordLogin}
        handleLogout={handleLogout}
      />

      <main style={styles.pageStyle}>
      <Container style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
      <div className="float">
        {renderLists(leftList)}
      </div>
      <div className="float">
        {renderLists(rightList)}
      </div>
      </Container>
    </main>

    <InfoModal isOpen={isModalOpen} onClose={handleCloseModal} selectedLink={selectedLink} />
    <DiscordModal isOpen={discordModalOpen} onClose={handleCloseModal} initiateDiscordLogin={initiateDiscordLogin} />
    <JoinServerModal isOpen={joinServerModalOpen} onClose={handleCloseModal} />
  
    <Footer />
    </div>
  );
}

// Apply styles to the body element
document.body.style = `
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export default Main;
